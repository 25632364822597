.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes Focus-Animation {
  0% { 
    opacity: 1;
    background-position: 70% 70%;
    outline: 2px solid rgba(255,255,255,.3);
  }
  
  50% { 
    opacity: 1;
    background-position: 10% 30%;
    outline: 2px solid rgba(255,255,255,1);
  }
  
  100% { 
    opacity: 1;
    background-position: 0% 30%;
    outline: 2px solid rgba(255,255,255,.3);
  }
}


.max-w-cart-sidebar .min-w-cart-sidebar{
  background-color: #282c34!important;
}

.carousel-indicatorProps{
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin: 5px!important;
  justify-content: space-evenly;
  overflow: clip !important;
  z-index: 25;
  opacity: 0.7;
}

.carousel-indicatorProps:focus{
  animation: Focus-Animation 2s ease-in-out infinite;
}

@media (min-width: 850px){
  .carousel-indicatorProps{
    width: 40px;
    height: 40px;
  }
}

.carousel-indicatorProps:hover{
  opacity: 1;
}

.carousel-containerProps {
  display: none!important;
}

@media (min-width: 550px){
  .carousel-containerProps {
    display: flex!important;
  }
}